import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Maybe you should use the `}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`Trailguide App`}</a>{` to navigate?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      